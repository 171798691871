<template>
  <div class="price-csv-analysis-container mt-2">
    <v-card v-if="file">
      <div class="d-flex align-start price-csv-analysis pa-2">
        <v-icon>mdi-information</v-icon>
        <div class="">
          <h5>CSV内容確認</h5>
          <template v-if="this.csv.length">
            <div>CSVの中の施設ID ({{facilityCount}}), {{facilityNames.join(', ')}}</div>
            <div>一番古い日付, {{oldestDate | jaShortDateSlash}}</div>
            <div>一番新しい日付, {{newestDate | jaShortDateSlash}}</div>
          </template>
          <template v-else>
            <div>ファイルが空です</div>
          </template>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import gql from 'graphql-tag'
function csvToArray (str, delimiter = ',') {
  const rows = str.split('\n')

  const arr = rows.reduce(function (result, row) {
    if (row) {
      const values = row.split(delimiter)
      result.push(values)
    }
    return result
  }, [])

  return arr
}

export default {
  props: {
    file: File
  },
  data () {
    return {
      csv: [],
      header: [],
      facilities: []
    }
  },
  watch: {
    file: {
      immediate: true,
      handler (file) {
        if (file) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const text = e.target.result
            const whole = csvToArray(text);
            this.header = whole[0]
            this.csv = whole.slice(1)
            // determine the encoding. if the first row contains �, it's probably shift-jis
            if (this.header.some(cell => cell.includes('�'))) {
              this.$emit('encoding', 'shift-jis')
            } else {
              this.$emit('encoding', 'utf-8')
            }
          }
          reader.readAsText(file)
        } else {
          this.csv = []
        }
      }
    }
  },
  computed: {
    facilityIds () {
      const ids = this.csv.map(row => Number(row[0]))
      return [...new Set(ids)]
    },
    facilityCount () {
      return this.facilityIds.length
    },
    facilityNames () {
      return this.facilityIds.map(id => {
        const found = this.facilities.find(f => f.id === id)
        if (found) return found.name
        return id
      })
    },
    dates () {
      return this.csv.map(row => new Date(row[2]))
    },
    newestDate () {
      return this.dates.reduce((max, date) => {
        if (date && date > max) {
          return date
        }
        return max
      }, this.dates[0])
    },
    oldestDate () {
      return this.dates.reduce((min, date) => {
        if (date && date < min) {
          return date
        }
        return min
      }, this.dates[0])
    }
  },
  apollo: {
    facilities: {
      query: gql`query {
        facilityList{
          id
          name
        }
      }`,
      update: (data) => data.facilityList
    }
  }
}
</script>

<style lang="scss" scoped>
.price-csv-analysis-container {
  max-width: 500px;
  & ::v-deep .price-csv-analysis {
    background-color: #888;
    color: white;

    & i {
      color: white;
    }
  }
}
</style>
