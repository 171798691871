<template>
  <base-fees-points>
    <template v-slot:default>
        <v-btn @click="downloadCSV(csvFile)" class="mt-5" color="var(--text__primary)" outlined :disabled="!checkPerUser">
          料金CSVフォーマットダウンロード
        </v-btn>
      <h2 class="page-title-main mt-5 mb-3">
        {{ $t("feesPoints.CSV.CSVRegistration") }}
      </h2>
      <v-file-input
        :disabled="!checkPerUser"
        v-model="csvFileUpload"
        class="t-file"
        filled
        hide-details
        :rules="uploadRules"
        accept=".csv"
        prepend-icon="mdi-file-document-outline"
        :label="$t('feesPoints.CSV.uploadaCSVFile')"
      ></v-file-input>
      <PriceCSVAnalysis :file="csvFileUpload" @encoding="encodingImmediate = $event" />
      <v-btn class="btn--primary mt-5" @click="loadCsvFile" :disabled="!checkPerUser" :loading="immediateUploading">{{
        $t("feesPoints.CSV.CSVUpdate")
      }}</v-btn>

      <v-col cols="12" lg="7">
        <div class="d-flex justify-space-between mt-6 mb-2">
          <h2 class="page-title-main">
            {{ $t("feesPoints.CSV.CSVUpdateSchedulel") }}
          </h2>
          <v-btn class="btn--primary" @click="visible = 1" :disabled="!checkPerUser">{{
            $t("feesPoints.CSV.newCSV")
          }}</v-btn>
        </div>

        <v-data-table
          disable-sort
          :locale="$i18n.locale"
          :headers="headerUpdate"
          :items="accommodationPriceImportList"
          :server-items-length="accommodationPriceImportTotal"
          class="elevation-2 table-custom table-no-pagination"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageText:
              accommodationPriceImportTotal +
              $t('common.cases') +
              itemStartTop +
              '~' +
              itemStopTop +
              $t('common.showing'),
            itemsPerPageOptions: [10, 20, 50, 100, 200, 500]
          }"
          @pagination="getNumOfPageTop"
        >
          <template v-slot:[`item.scheduledAt`]="{item}">
            {{ item.scheduledAt | dateTimeSting }}
          </template>
          <template v-slot:[`item.createdAt`]="{item}">
            {{ item.createdAt | dateTimeSting }}
          </template>
          <template v-slot:[`item.cancelUpdate`]="{ item }">
            <v-btn icon v-if="item.status === '更新待ち'" color="var(--bg__white)" class="btn--red-dark" @click="cancelUpdate(item)" :loading="cancelling[item.id]">
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <h2 class="page-title-main mt-6 mb-2">
          {{ $t("feesPoints.CSV.nonProductCharges") }}
        </h2>
        <v-data-table
          @click:row="getSelect($event)"
          disable-sort
          :locale="$i18n.locale"
          :headers="headerExtraCharge"
          :items="noneProductList"
          class="elevation-2 table-custom table-no-footer"
          :footer-props="{
            itemsPerPageText:
              numLength +
              $t('common.cases') +
              itemStart +
              '~' +
              itemStop +
              $t('common.showing'),
            itemsPerPageOptions: [10, 20, 50, 100, 200, 500]
          }"
          @pagination="getNumOfPage"
        >
          <template v-slot:[`item.csv`]="{}">
            <v-btn :disabled="!checkPerUser" icon color="var(--bg__primary)" class="btn--primary" @click="visible = 2">
              <v-icon
                >mdi-file-document-outline</v-icon
              >
            </v-btn>
          </template>
        </v-data-table>
      </v-col>

      <!---------- Fee point CSV upload (fee master only) ---------->
      <BaseDialog
        width="550"
        :visible="visible === 1"
        @close="visible = -1"
        :title="$t('feesPoints.CSV.feePointCSVUpload')"
      >
        <template v-slot:content>
        <v-form ref="form">
            <v-file-input
            v-model="csvUpload"
            class="mt-5"
            filled
            :rules="upFile"
            accept=".csv"
            prepend-icon="mdi-file-document-outline"
            :label="showLabel()"
          ></v-file-input>

        <PriceCSVAnalysis :file="csvUpload" @encoding="encodingScheduled = $event" />
        </v-form>
          <div class="d-flex align-center mt-5">
            <v-icon class="align-self-end">mdi-calendar</v-icon>
            <!---------- Date picker ---------->
            <v-menu
              v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <div>
                  <div>
                    <h5 class="mb-1" style="color:#000000; font-size: 10px !important">{{ $t("feesPoints.CSV.updateDate") }}</h5>
                    <v-btn
                      v-on="on"
                      small
                      class="btn--date mr-5"
                      >{{ formatDate(scheduledAt) }}</v-btn
                    >
                  </div>
                </div>
              </template>
              <v-date-picker
                v-model="scheduledAt"
                :min="new Date().toISOString().substr(0, 10)"
                :first-day-of-week="0"
                :locale="$i18n.locale"
                scrollable
                @input="menuDate = false"
                class="v-date-picker-custom"
              ></v-date-picker>
            </v-menu>
            <!---------- End date picker ---------->
            <div>
              <h5 class="mb-1" style="color:#000000; font-size: 10px !important">{{ $t("common.time") }}</h5>
              <v-select
                v-model="hourAndMinutes"
                :items="listHourAndMinute"
                dense
                small
                color="var(--text__gray)"
                hide-details
                class="t-select align-self-end"
              ></v-select>
            </div>
          </div>

          <h5 class="mt-5" style="color:#000000; font-size: 10px !important">{{ $t("common.remark") }}</h5>
          <v-text-field
            hide-details
            v-model="remarks"
            class="pt-0"
          ></v-text-field>

          <h5 class="mt-5" style="color:#000000; font-size: 10px !important">{{ $t('feesPoints.CSV.registrant') }}</h5>
          <v-text-field
            hide-details
            v-model="authorizedBy"
            class="pt-0"
          ></v-text-field>

        </template>
        <template v-slot:footer>
          <div class="d-flex justify-end mt-5">
            <v-btn large class="btn--red-dark" @click="visible = -1">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t("buttons.close") }}
            </v-btn>
            <v-btn large class="btn--primary ml-3" @click="uploadCsvFile()">
              {{ $t("buttons.register") }}
            </v-btn>
          </div>
        </template>
      </BaseDialog>

      <!---------- CSV upload of non-product fees ---------->
      <BaseDialog
        width="520"
        :visible="visible === 2"
        @close="visible = -2"
        :title="$t('feesPoints.CSV.CSVUploadNoFees')"
      >
        <template v-slot:content>
         <v-form ref="formNoneProduct">
            <v-file-input
            v-model="csvNoneProductUpload"
            class="mt-5"
            filled
            :rules="fileNoneUpload"
            accept=".csv"
            prepend-icon="mdi-file-document-outline"
            :label="showLabelNoneProduct()"
          ></v-file-input>
          <PriceCSVAnalysis :file="csvNoneProductUpload" @encoding="encodingNonProduct = $event" />
         </v-form>
        </template>
        <template v-slot:footer>
          <div class="d-flex justify-end mt-5">
            <v-btn large class="btn--red-dark" @click="visible = -2">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t("buttons.close") }}
            </v-btn>
            <v-btn large class="btn--primary ml-3" @click="uploadCsvNoneProductFile()">
              {{ $t("buttons.register") }}
            </v-btn>
          </div>
        </template>
      </BaseDialog>
    </template>
  </base-fees-points>
</template>

<script>
import BaseFeesPoints from '@/components/FeesPoints/BaseFeesPoints'
import BaseDialog from '@/components/Dialog/BaseDialog'
import { formatDate, handlError } from '@/constants/functions'
import { csvDefault } from '@/constants/csv'
import { downloadCSV } from '@/utils/downloadCSV'
import { UPLOAD_CSV_ACCOMMODATION_PRICE, UPLOAD_CSV_ACC_PRICE, NONE_PRODUCT_LIST, UPLOAD_CSV_NONE_PRODUCT_PRICE, CANCEL_CSV_UPDATE } from '@/api/graphql/csv/uploadCsvAccommodationPrice'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import PriceCSVAnalysis from './PriceCSVAnalysis.vue'
import gql from 'graphql-tag'

export default {
  name: 'CSVUpdate',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      hourAndMinutes: '0:00',
      listHourAndMinute: [],
      csvNoneProductUpload: null,
      nonProductId: null,
      noneProductList: [],
      scheduledAt: new Date().toISOString().substring(0, 10),
      authorizedBy: '',
      remarks: '',
      csvFileUploadNonProduct: null,
      csvUpload: null,
      csvFileUpload: null,
      csvFile: csvDefault,
      uploadRules: [value => !value || value.size < 10000000 || '10MBを超えることはできません'],
      upFile: [v => !!v || this.$t('rules.isRequired')],
      fileNoneUpload: [v => !!v || this.$t('rules.isRequired')],
      encodingImmediate: null,
      encodingScheduled: null,
      encodingNonProduct: null,
      headerUpdate: [
        { text: this.$t('common.status'), value: 'status' },
        {
          text: this.$t('feesPoints.CSV.scheduledUpdateDate'),
          value: 'scheduledAt'
        },
        { text: this.$t('feesPoints.CSV.registrant'), value: 'authorizedBy' },
        { text: this.$t('common.remark'), value: 'remarks' },
        {
          text: this.$t('feesPoints.CSV.registrationDate'),
          value: 'createdAt'
        },
        {
          text: this.$t('feesPoints.CSV.cancelUpdate'),
          value: 'cancelUpdate',
          align: 'center',
          width: '100px'
        }
      ],
      headerExtraCharge: [
        { text: this.$t('feesPoints.CSV.nonProductChargeName'), value: 'name' },
        {
          text: this.$t('feesPoints.CSV.CSVUpload'),
          value: 'csv',
          align: 'center',
          width: '100px'
        }
      ],
      listExtraCharge: [
        { name: 'フェザント山中湖会員' },
        { name: '東急ハーヴェスト会員' },
        { name: 'Ewel（補助金あり）' },
        { name: 'Ewel（補助金なし）' },
        { name: 'パームショア熱海住民利用' },
        { name: 'リゾートワークス' },
        { name: '体験宿泊' }
      ],
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      itemStartTop: 0,
      itemStopTop: 0,
      // -----------------------------------------

      // DIALOGS -----------------------------------
      visible: -1,
      menuDate: false,
      date: new Date().toISOString().substr(0, 10),
      immediateUploading: false,
      cancelling: {}
    }
  },

  watch: {
    visible () {
      if (this.visible === -1) {
        this.$refs.form.resetValidation()
        this.scheduledAt = new Date().toISOString().substr(0, 10)
        this.authorizedBy = ''
        this.hourAndMinutes = ''
        this.remarks = ''
        this.csvUpload = null
        this.csvNoneProductUpload = null
      }
      if (this.visible === -2) {
        this.$refs.formNoneProduct.resetValidation()
        this.csvNoneProductUpload = null
      }
    }
  },

  mounted () {
    this.getNoneProductList()
    this.fetchAccommodationPriceImportList()
    this.getListHourAndMinute()
  },
  computed: {
    ...mapGetters(['accommodationPriceImportList', 'accommodationPriceImportTotal']),
    scheduledAtDateTime () {
      return new Date(this.scheduledAt + ' ' + this.hourAndMinutes)
    }
  },

  methods: {
    ...mapActions(['fetchAccommodationPriceImportList']),
    ...mapMutations(['setAlertSuccess', 'setErrorDialog', 'setAlertError', 'setLoadingOverlayShow', 'setLoadingOverlayHide', 'setAccommodationPriceImportListTake', 'setAccommodationPriceImportListSkip']),
    getListHourAndMinute () {
      var arr = []; var i
      for (i = 0; i < 24; i++) {
        if (i < 10) {
          arr.push('0' + i + ':00 ')
        } else {
          arr.push(i + ':00 ')
        }
      }
      this.listHourAndMinute = arr
    },

    getSelect (event) {
      this.nonProductId = event.id
    },

    async getNoneProductList () {
      this.setLoadingOverlayShow()
      await this.$apollo.query({
        query: gql`${NONE_PRODUCT_LIST}`
      }).then((data) => {
        this.noneProductList = data.data.nonProductList
      }).catch((error) => {
        this.setLoadingOverlayHide()
        console.error(error)
      })
    },

    showLabel () {
      if (!this.csvUpload) {
        return this.$t('feesPoints.CSV.uploadaCSVFile')
      }
    },
    showLabelNoneProduct () {
      if (!this.csvNoneProductUpload) {
        return this.$t('feesPoints.CSV.uploadaCSVFile')
      }
    },

    uploadCsvNoneProductFile () {
      if (this.$refs.formNoneProduct.validate()) {
        if (this.csvNoneProductUpload) {
          var reader = new FileReader()
          const encoding = this.encodingNonProduct ?? 'utf-8';
          reader.readAsText(this.csvNoneProductUpload)
          reader.onload = () => {
            var file = new Blob([reader.result], { type: `text/csv;charset=${encoding};` })
            file.name = 'csvNoneProductFile.txt'
            this.$apollo.mutate({
              mutation: UPLOAD_CSV_NONE_PRODUCT_PRICE,
              variables: {
                data: {
                  file,
                  nonProductId: this.nonProductId
                }

              }
            }).then((data) => {
              this.setAlertSuccess(this.$t('messages.successfulUpdate'))
              this.visible = -2
            }).catch((error) => {
              this.visible = -2
              const errors = error.graphQLErrors ?? (Array.isArray(error) ? error : [error])
              handlError(this.$store.commit, errors)
            })
          }
        }
      }
    },

    uploadCsvFile () {
      if (this.$refs.form.validate()) {
        if (this.csvUpload) {
          var reader = new FileReader()
          const encoding = this.encodingScheduled ?? 'utf-8';
          reader.readAsText(this.csvUpload, encoding)
          reader.onload = () => {
            var file = new Blob([reader.result], { type: `text/csv;charset=${encoding};` })
            file.name = 'csvFile.txt'
            this.$apollo.mutate({
              mutation: UPLOAD_CSV_ACC_PRICE,
              variables: {
                data: {
                  scheduledAt: this.scheduledAtDateTime,
                  file,
                  authorizedBy: this.authorizedBy,
                  remarks: this.remarks
                }

              }
            }).then((data) => {
              this.setAlertSuccess(this.$t('messages.successfulUpdate'))
              this.visible = -1
            }).catch((error) => {
              this.visible = -1
              const errors = error.graphQLErrors ?? (Array.isArray(error) ? error : [error])
              handlError(this.$store.commit, errors)
            })
          }
        }
      }
    },
    async cancelUpdate (row) {
      const id = row.id
      try {
        this.$set(this.cancelling, id, true)
        await this.$apollo.mutate({
          mutation: CANCEL_CSV_UPDATE,
          variables: { id }
        }).then(() => {
          this.setAlertSuccess('キャンセルに成功しました')
        }).catch((error) => {
          const errors = error.graphQLErrors ?? (Array.isArray(error) ? error : [error])
          handlError(this.$store.commit, errors)
          this.csvFileUpload = null
        })
      } finally {
        this.$set(this.cancelling, id, false)
      }
    },

    loadCsvFile () {
      if (this.csvFileUpload) {
        try {
          this.immediateUploading = true
          var reader = new FileReader()
          const encoding = this.encodingImmediate ?? 'utf-8';
          reader.readAsText(this.csvFileUpload, encoding)
          reader.onload = () => {
            var file = new Blob([reader.result], { type: `text/csv;charset=${encoding};` })
            file.name = 'csvFile.txt'
            this.$apollo.mutate({
              mutation: UPLOAD_CSV_ACCOMMODATION_PRICE,
              variables: {
                scheduledAt: null, // null for update now
                file
              }
            }).then(() => {
              this.setAlertSuccess(this.$t('messages.successfulUpdate'))
              this.csvFileUpload = null
            }).catch((error) => {
              const errors = error.graphQLErrors ?? (Array.isArray(error) ? error : [error])
              handlError(this.$store.commit, errors)
              this.csvFileUpload = null
            })
          }
        } finally {
          this.immediateUploading = false
        }
      } else {
        this.setAlertError('ファイルが選択されていません')
      }
    },
    formatDate,
    downloadCSV,
    getNumOfPage (pagination) {
      this.numLength = pagination.itemsLength
      this.itemStart = pagination.pageStart + 1
      this.itemStop = pagination.pageStop
    },
    getNumOfPageTop (pagination) {
      this.itemStartTop = pagination.pageStart + 1
      this.itemStopTop = pagination.pageStop
      this.setAccommodationPriceImportListTake(pagination.itemsPerPage)
      this.setAccommodationPriceImportListSkip((pagination.page - 1) * pagination.itemsPerPage)
      this.fetchAccommodationPriceImportList()
    }
  },
  components: {
    BaseFeesPoints,
    BaseDialog,
    PriceCSVAnalysis
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-data-table {
    thead th span {
      font-weight: normal;
      color: #757575 !important;
      font-size: 14px !important;
    }
    tbody tr td {
      font-size: 14px !important;
      color: #232222 !important;
    }
  }
  .v-data-footer__select {
    font-size: 13px !important;
    color: #000000 !important;
  }
  .v-select__selections {
    .v-select__selection--comma {
      color: #000000 !important;
      font-size: 13px !important;
    }
  }
  span {
    .v-icon__svg {
      color: #333333 !important
    }
  }
  .t-file {
    max-width: 350px;
  }
  .t-select {
    max-width: 100px;
  }
  .table-no-footer {
    .v-data-footer {
      display: none;
    }
  }
}
</style>
